.width_100P {
    width: 100% !important;
}

.mv-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mh-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.f-12 {
    font-size: 12px !important;
}