
.color-grey-900 {
  color: rgba(33, 33, 33, 1)
}

.color-white {
  color: white !important;
}

$header-color: #2b3643;
$dark-color: #364150;
$primary-color: #32c5d2 !important;
$white-color: #fff;
$primary-color-hover: #099BB6 !important;

.danger-color {
  color: #ff0c05 !important;
}
