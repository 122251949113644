@import "../../styles/Scss/main";

.overview-personal-info {
  margin-top: 24px;
  margin-bottom: 40px;
  margin-right: 10px;
}

.profile-img {
  max-width: 300px;
  width: 100%;
  padding: 30px !important;
}

.user-data {
  margin-bottom: 5px;

  i {
    font-size: 20px;
    margin-right: 6px;
  }
}

.trainerStat { 
  height: 100%;
  padding: 20px; 

  .trainerStatFirstDiv{
    height: 50%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    
    .sessionStyle {
      width: 33%;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;
      p {text-align:center;}
    }
    .retentionStyle {
      flex-direction:column;
      justify-content: center;
      align-items: center; 
      position: relative;
      p {text-align:center; margin-top: 10px;}}
    .progressStyle {
      width:34%; 
      height:50px; 
      margin-bottom:50px;
      .CircularProgressbar {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
      } 
    }
  }
}

.signin-type{
padding: 8px;
width: 75%;
color: white;
font-weight: bold;
text-align: center;
}
.google-signin-color{
  background: rgba(255, 0, 0, 0.8);
}
.facebook-signin-color{
  background: #3578e5;
}
.apple-signin-color{
  background: black;
}
.credential-signin-color{
  background: #32c5d2;
}

/* Dropdown container */
.package-dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown button */
.package-dropdown-toggle {
  background-color: white;
  color: #000;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  cursor: pointer;
  outline: none;
}

.package-dropdown-toggle:hover, .package-dropdown-toggle:focus, .package-dropdown-toggle:active {
  outline: none;
  background-color: white !important;
}

/* Dropdown content */
.package-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%;
}

/* Show dropdown content when dropdown button is clicked */
.package-dropdown:hover .package-dropdown-content {
  display: block;
}

/* Style the dropdown links */
.package-dropdown-content .package-dropdown-item {
  color: #000;
  padding: 10px 15px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.package-dropdown-content .package-dropdown-item:hover {
  background-color: #f1f1f1;
}

.package-purchase-date{
  font-size: 12px;
  color: #777777;
}