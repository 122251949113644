@import "../../styles/Scss/main";

.session-item {
  text-align: center;
}
.amount-info {
  font-size: 16px;
  border-bottom: 5px solid grey;
}
.amount-row {
  text-align: center;
  display: inline-block !important;
}
.label-row {
  text-align: center;
  display: inherit !important;
}
